// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }`, "",{"version":3,"sources":["webpack://./src/dist/css/spinner.css"],"names":[],"mappings":"AAAA;IACI;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;EACA;IACE,WAAW;IACX,YAAY;IACZ,0BAA0B,EAAE,eAAe;IAC3C,8BAA8B,EAAE,SAAS;IACzC,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf","sourcesContent":["@keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  .loading-spinner {\n    width: 50px;\n    height: 50px;\n    border: 10px solid #f3f3f3; /* Light grey */\n    border-top: 10px solid #383636; /* Blue */\n    border-radius: 50%;\n    animation: spinner 1.5s linear infinite;\n  }\n  \n  .spinner-container {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    height: 350px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
