// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-collapse: collapse;
  }
  
  th,
  td {
    /* border: 1px solid black; */
    /* padding: 0px !important; */
    /* padding: 0.5rem 1rem 0.5rem 1rem; */
  }
  th{
padding: 10px !important; 
  }
  
  .resizer {
    display: inline-block;
    background: gray;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    /* prevent scroll on touch devices */
    touch-action: none;
  }
  
  .isResizing {
    background: rgb(4, 204, 204);
  }
  
 .resultTable{
   thead,
   tbody,
   tfoot,
   tr,
   td,
   th {
     padding: 10px;
     border-color: inherit;
     border-style: solid;
     border-width: 0;
    }
  }
  .diagTable{
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th
    {    
      border-color: inherit;
      border-style: none !important;
      border-width: 0;
    }
  }
  
  tbody>tr:hover{
      background-color: rgba(0, 76, 136, 0.15)  !important;
    }
  `, "",{"version":3,"sources":["webpack://./src/dist/css/data-table.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;;IAEE,6BAA6B;IAC7B,6BAA6B;IAC7B,sCAAsC;EACxC;EACA;AACF,wBAAwB;EACtB;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,0BAA0B;IAC1B,UAAU;IACV,oCAAoC;IACpC,kBAAkB;EACpB;;EAEA;IACE,4BAA4B;EAC9B;;CAED;GACE;;;;;;KAME,aAAa;KACb,qBAAqB;KACrB,mBAAmB;KACnB,eAAe;IAChB;EACF;EACA;IACE;;;;;;;MAOE,qBAAqB;MACrB,6BAA6B;MAC7B,eAAe;IACjB;EACF;;EAEA;MACI,oDAAoD;IACtD","sourcesContent":["table {\n    border-collapse: collapse;\n  }\n  \n  th,\n  td {\n    /* border: 1px solid black; */\n    /* padding: 0px !important; */\n    /* padding: 0.5rem 1rem 0.5rem 1rem; */\n  }\n  th{\npadding: 10px !important; \n  }\n  \n  .resizer {\n    display: inline-block;\n    background: gray;\n    width: 1px;\n    height: 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n    transform: translateX(50%);\n    z-index: 1;\n    /* prevent scroll on touch devices */\n    touch-action: none;\n  }\n  \n  .isResizing {\n    background: rgb(4, 204, 204);\n  }\n  \n .resultTable{\n   thead,\n   tbody,\n   tfoot,\n   tr,\n   td,\n   th {\n     padding: 10px;\n     border-color: inherit;\n     border-style: solid;\n     border-width: 0;\n    }\n  }\n  .diagTable{\n    thead,\n    tbody,\n    tfoot,\n    tr,\n    td,\n    th\n    {    \n      border-color: inherit;\n      border-style: none !important;\n      border-width: 0;\n    }\n  }\n  \n  tbody>tr:hover{\n      background-color: rgba(0, 76, 136, 0.15)  !important;\n    }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
