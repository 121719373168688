export const LOGIN_ROUTE = 'login'
export const PRIVACY_POLICY_ROUTE = 'privacy-policy'
export const REGISTER_ROUTE = 'register'
export const LINKPAGE_ROUTE = 'linkpage'
export const UNAUTHORIZED_ROUTE = 'unauthorized'
export const EDITOR_ROUTE = 'editor'
export const LOUNGE_ROUTE = 'lounge'
export const ADMIN_ROUTE = 'admin'

export const ADD_SAMPLE_ROUTE = 'addsample'
export const HOME_ROUTE = 'dashboard'
export const DASHBOARD_ROUTE = 'dashboard'
export const USERS_ROUTE = 'users/page/:pageNumber'
export const USERS_ID_ROUTE = 'users/:id'
export const DOCTORS_ROUTE = 'doctors/list/page/:pageNumber'
export const DOCTORS_ID_ROUTE = 'doctors/:id'
export const DOCTORS_EMPLOYMENT_ROUTE = 'doctors/employment'
export const REFDOCTORS_ROUTE = 'doctors/refDoctors/page/:pageNumber'
export const DOCTORSVISITS_ROUTE = 'doctorsVisits/page/:pageNumber'
export const DOCTORSVISITS_ID_ROUTE = 'doctorsVisits/:id'
export const SAMPLES_ROUTE = 'samples'
export const AGENTS_ROUTE = 'agents/page/:pageNumber'
export const ORGANIZATIONS_ROUTE = 'organizations/page/:pageNumber'
export const ORGANIZATIONS_ID_ROUTE = 'organizations/:id'
export const MEDINSTITUTIONS_ROUTE = 'medInstitutions/page/:pageNumber'
export const PATIENTS_ROUTE = 'patients/page/:pageNumber'
export const PATIENTS_ID_ROUTE = 'patients/:id'
export const DOCTOR_PATIENTS_ID_ROUTE = 'patients/doctorarea/:id'
export const SETUP_ROUTE = 'setup'
export const DISCOUNT_CARDS_ROUTE = 'setup/discountCards'
export const REAGENTS_ROUTE = 'setup/reagents/page/:pageNumber'
export const EQUIPMENTS_ROUTE = 'setup/equipments/page/:pageNumber'
export const RESEARCH_LISTS_ROUTE = 'setup/researchlists/page/:pageNumber'
export const MEDICALSERVICES_ROUTE = 'setup/medicalServices/page/:pageNumber'
export const PURCHASETWO_ROUTE = 'purchases/purchaseTwo'
export const DIAGNOSTICS_ROUTE = 'diagnostics/page/:pageNumber'
export const DIAGNOSTICS_ID_ROUTE = 'diagnostics/:id'
export const MISSING_ROUTE = '*'
export const PACKAGES_ROUTE = 'setup/packages/page/:pageNumber'
export const REPORTSEXPORT_ROUTE = 'reports/export'
export const NOTIFICATIONS_ROUTE = 'setup/notifications/page/:pageNumber'

export const DOCTORSTAMPLETE_ROUTE = 'doctorsTemplete'
export const DOCTORSPATIENT_ID_ROUTE = 'doctorsPatients/:id'
export const DOCTORSPATIENTS_URL = "/patients/:doctorId";
export const DOCTORS_PATIENTS = 'doctorsPatients'
export const DOCTORS_PATIENTS_ID_ROUTE = 'doctorsPatients/:id'

export const REGISTER_DOCTORS_PATIENT = "/registerDoctorsPatient";
export const REGISTER_DIAGNOSTICS = "/registerDiagnostics";
export const REGISTER_PATIENT = "/registerPatient";
export const REGISTER_AGENT = "/registerAgent";
export const REGISTER_ORGANIZATIONS = "/registerOrganizations";
export const REGISTER_DOCTORS = "/registerDoctors";
export const REGISTER_DOCTORSVISITS = "/registerDoctorsVisit";
export const REGISTER_DISCOUNT = "/registerDiscount";
export const REGISTER_USER = "/registerUser";
export const REGISTER_REAGENT = "/registerReagent";
export const REGISTER_EQUIPMENT = "/registerEquipment";
export const REGISTER_RESEARCHLISTS = "/registerResearchLists";
export const REGISTER_ROLE = "/registerRole";
export const REGISTER_REFDOCTOR = "/registerRefDoctors";
export const REGISTER_MEDINSTITUTION = "/registerMedInstitutions";
export const REGISTER_MEDICALSERVICES = "/registerMedicalServices";
export const REGISTER_PACKAGES = "/registerPackage";

export const RESEARCHLISTS_URL = "/researchLists";
export const MEDICALSERVICES_URL = "/medicalServices";
export const PATIENTS_URL = "/patients";
export const DOCTORS_URL = "/doctors";
export const DISCOUNTS_URL = "/discounts";
export const REFDOCTORS_URL = "/refDoctors";
export const MEDINSTITUTIONS_URL = "/medInstitutions";
export const SAMPLES_URL = "/samples";
export const USERS_URL = "/users";
export const DIAGNOSTICS_URL = "/diagnostics";
export const AGENTS_URL = "/agents";
export const ORGANIZATIONS_URL = "/organizations";
export const REAGENTS_URL = "/reagentList";
export const EQUIPMENTS_URL = "/equipmentList";
export const SUPPORT_URL = "/support";
export const DOCTORSVISITS_URL = '/doctorsVisits';
export const PACKAGES_URL = "/customPackage";
export const NOTIFICATIONS_URL = "/notifications";

export const PATIENTS__SEARCH_URL = "/searchPatient";
export const DIAGNOSTICS__SEARCH_URL = "/searchDiagnostics";
export const DOCTORSVISITS__SEARCH_URL = "/searchDoctorsVisits";

export const STATISTICS_URL = '/getStatistics/StackedBarChart'

export const CREATE_POS_PAY = '/posPayment'


export const ROLES = {
    User: 2001,
    Editor: 1984,
    Approver: 3345,
    Admin: 5150,
    Sampler: 1212,
    Doctor:9578,
    SuperAdmin:5050
  };
